@use 'shared/shadows';

/* Default */
.shadow-default {
  box-shadow: shadows.$shadow-default;
}

.shadow-default-2 {
  box-shadow: shadows.$shadow-default-2;
}

.shadow-default-red {
  box-shadow: shadows.$shadow-default-red;
}

.shadow-default-green {
  box-shadow: shadows.$shadow-default-green;
}

.shadow-default-blue {
  box-shadow: shadows.$shadow-default-blue;
}

/* Hover */
.shadow-hover {
  box-shadow: shadows.$shadow-hover;
}

.shadow-hover-red {
  box-shadow: shadows.$shadow-hover-red;
}

.shadow-hover-green {
  box-shadow: shadows.$shadow-hover-green;
}

.shadow-hover-image {
  box-shadow: shadows.$shadow-hover-image;
}

/* Pressed */
.shadow-pressed {
  box-shadow: shadows.$shadow-pressed;
}

.shadow-pressed-red {
  box-shadow: shadows.$shadow-pressed-red;
}

.shadow-pressed-green {
  box-shadow: shadows.$shadow-pressed-green;
}

/* Product, FAQ, Card */
.shadow-product {
  box-shadow: shadows.$shadow-product;
}

.shadow-product-dark {
  box-shadow: shadows.$shadow-product-dark;
}

.shadow-product-image {
  box-shadow: shadows.$shadow-product-image;
}

.shadow-image-card {
  box-shadow: shadows.$shadow-image-card;
}

.shadow-faq {
  box-shadow: shadows.$shadow-faq;
}

.shadow-card {
  box-shadow: shadows.$shadow-card;
}
