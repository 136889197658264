/*------------------------------------
  Colors
------------------------------------*/
$blue-1: #f0f2fe;
$blue-2: #eceffe;
$blue-3: #e1e6fd;
$blue-4: #b4c0fa;
$blue-5: #8fa0f8;
$blue-6: #6a81f6;

$red-1: rgba(255, 57, 127, 0.1);
$red-2: rgba(255, 57, 127, 0.15);
$red-3: rgba(255, 57, 127, 0.2);
$red-4: #ff397f;

$gray-1: #f4f4f6;
$gray-2: #efeff4;
$gray-3: #e6e6eb;
$gray-4: #c7c7d2;
$gray-5: #9599aa;

$transparent-1: rgba(255, 255, 255, 0.1);
$transparent-2: rgba(255, 255, 255, 0.15);
$transparent-3: rgba(255, 255, 255, 0.2);
$transparent-4: rgba(255, 255, 255, 0.35);

/*------------------------------------
  Common button styles
------------------------------------*/
/* Default */
.gbtn {
  padding: 7px 30px;
  border: 1px solid transparent;
  border-radius: 100px;
  color: $blue-6 !important;
  background: transparent;
  text-decoration: none !important;
  text-align: center;
  display: inline-block;
  transition: all 0.1s;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &.disabled,
  &:disabled {
    color: $gray-4 !important;
    cursor: default;
    pointer-events: none;
  }
}

.gbtn-red {
  color: $red-4 !important;
}

/*------------------------------------
  Button variants
------------------------------------*/
/* Variant 1 */
@mixin variant-1($default, $hover, $active, $disabled) {
  @extend .gbtn;
  background: $default;

  &:hover {
    background: $hover;
  }

  &:active,
  &.active {
    background: $active;
  }

  &.disabled,
  &:disabled {
    background: $disabled !important;
  }
}

.gbtn-1 {
  @include variant-1($blue-2, $blue-1, $blue-3, $gray-1);
}

.gbtn-red-1 {
  @extend .gbtn-red;
  @include variant-1($red-2, $red-1, $red-3, $gray-1);
}

/* Variant 2 */
@mixin variant-2($default, $hover, $active, $disabled) {
  @extend .gbtn;
  border-color: $default;

  &:hover {
    background: $hover;
    border-color: $default;
  }

  &:active,
  &.active {
    background: $active;
    border-color: $active;
  }

  &.disabled,
  &:disabled {
    background: transparent !important;
    border-color: $disabled !important;
  }
}

.gbtn-2 {
  @include variant-2($blue-3, $blue-1, $blue-3, $gray-2);
}

.gbtn-red-2 {
  @extend .gbtn-red;
  @include variant-2($red-3, $red-1, $red-3, $gray-2);
}

/* Variant 3 */
@mixin variant-3($hover, $active) {
  @extend .gbtn;

  &:hover {
    background: $hover;
  }

  &:active,
  &.active {
    background: $active;
  }

  &.disabled,
  &:disabled {
    background: transparent !important;
  }
}

.gbtn-3 {
  @include variant-3($blue-1, $blue-3);
}

.gbtn-red-3 {
  @extend .gbtn-red;
  @include variant-3($red-1, $red-3);
}

/* Variant 4 */
@mixin variant-4($default, $hover, $active, $disabled) {
  @extend .gbtn;
  background: $default;
  color: $white !important;

  &:hover {
    background: $hover;
  }

  &:active,
  &.active {
    background: $active;
  }

  &.disabled,
  &:disabled {
    color: $white !important;
    background: $disabled !important;
  }
}

.gbtn-4 {
  @include variant-4($blue-6, $blue-5, $blue-4, $gray-3);
}

.gbtn-red-4 {
  @extend .gbtn-red;
  @include variant-4($red-4, $red-4, $red-4, $gray-3);
}

/*------------------------------------
  Utilities
------------------------------------*/
/* Prefix */
.gbtn-prefix {
  padding-right: 23px;
  padding-left: 19px;

  > i {
    margin-right: 8px;
  }
}

/* Suffix */
.gbtn-suffix {
  padding-right: 19px;
  padding-left: 23px;

  > i {
    margin-left: 8px;
  }
}

/* Size - small */
.gbtn-sm {
  padding: 3px 15px;

  &.gbtn-prefix {
    padding-right: 15px;
    padding-left: 11px;
  }

  &.gbtn-suffix {
    padding-right: 11px;
    padding-left: 15px;
  }
}

/* Size - large */
@mixin gbtn-lg() {
  padding: 14px 38px;
  border-width: 2px;

  &.gbtn-prefix {
    padding-right: 31px;
    padding-left: 23px;
  }

  &.gbtn-suffix {
    padding-right: 23px;
    padding-left: 31px;
  }
}

.gbtn-lg {
  @include gbtn-lg();
}

/* Size - large on desktop and normal on mobile */
@media (min-width: 768px) {
  .gbtn-lg-md {
    @include gbtn-lg();
  }
}

/* Square */
.gbtn-sq {
  border-radius: 10px !important;
}

/* Shadow */
.gbtn-shadow {
  box-shadow: 0px 10px 20px 5px rgba(78, 95, 124, 0.1);

  &:active,
  &.active {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  }

  &.disabled,
  &:disabled {
    box-shadow: none;
  }
}

/* Loader */
.gbtn-spinner {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  > .spinner-border {
    margin-right: 0.5rem;
  }
}

/* Colored background */
.gbtn-bg {
  color: $white !important;

  &.disabled,
  &:disabled {
    color: $transparent-3 !important;
  }

  /* Variant 1 */
  &.gbtn-1 {
    background: $transparent-4;

    &:hover {
      background: $transparent-1;
    }

    &:active,
    &.active {
      background: $transparent-3;
    }

    &.disabled,
    &:disabled {
      background: $transparent-1 !important;
    }
  }

  /* Variant 2 */
  &.gbtn-2 {
    background: transparent;
    border-color: $transparent-3;

    &:hover {
      background: $transparent-1;
    }

    &:active,
    &.active {
      background: $transparent-3;
    }

    &.disabled,
    &:disabled {
      background: transparent !important;
      border-color: $transparent-3 !important;
    }
  }

  /* Variant 3 */
  &.gbtn-3 {
    background: transparent;

    &:hover {
      background: $transparent-1;
    }

    &:active,
    &.active {
      background: $transparent-3;
    }

    &.disabled,
    &:disabled {
      background: transparent !important;
    }
  }

  /* Variant 4 */
  &.gbtn-4 {
    color: $blue-6 !important;
    background: $white;

    &:hover {
      color: $white !important;
      background: $blue-5;
    }

    &:active,
    &.active {
      color: $white !important;
      background: $blue-4;
    }

    &.disabled,
    &:disabled {
      color: $transparent-3 !important;
      background: $transparent-1 !important;
    }
  }
}
