@font-face {
    font-family: "gpicons";
    font-display: "block";
    src: url("./gpicons.woff2?d460899cf457b777f5313c00586c1a89") format("woff2"),
url("./gpicons.woff?d460899cf457b777f5313c00586c1a89") format("woff");
}

i[class^="gpicon-"]:before, i[class*=" gpicon-"]:before {
    font-family: gpicons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.gpicon-add-data:before {
    content: "\f101";
}
.gpicon-add-plus:before {
    content: "\f102";
}
.gpicon-add-result:before {
    content: "\f103";
}
.gpicon-add:before {
    content: "\f104";
}
.gpicon-ancestry:before {
    content: "\f105";
}
.gpicon-apps:before {
    content: "\f106";
}
.gpicon-arrows-left:before {
    content: "\f107";
}
.gpicon-arrows-right:before {
    content: "\f108";
}
.gpicon-back-fat:before {
    content: "\f109";
}
.gpicon-back:before {
    content: "\f10a";
}
.gpicon-bank:before {
    content: "\f10b";
}
.gpicon-blood-phone:before {
    content: "\f10c";
}
.gpicon-blood:before {
    content: "\f10d";
}
.gpicon-body:before {
    content: "\f10e";
}
.gpicon-calendar:before {
    content: "\f10f";
}
.gpicon-camera-phone:before {
    content: "\f110";
}
.gpicon-camera-stars:before {
    content: "\f111";
}
.gpicon-camera:before {
    content: "\f112";
}
.gpicon-cart-2-filled:before {
    content: "\f113";
}
.gpicon-cart-2:before {
    content: "\f114";
}
.gpicon-cart:before {
    content: "\f115";
}
.gpicon-check:before {
    content: "\f116";
}
.gpicon-circle-check-solid:before {
    content: "\f117";
}
.gpicon-circle-check:before {
    content: "\f118";
}
.gpicon-circle-hollow:before {
    content: "\f119";
}
.gpicon-circle:before {
    content: "\f11a";
}
.gpicon-claim-kit:before {
    content: "\f11b";
}
.gpicon-close-filled:before {
    content: "\f11c";
}
.gpicon-close:before {
    content: "\f11d";
}
.gpicon-credit-card:before {
    content: "\f11e";
}
.gpicon-cross:before {
    content: "\f11f";
}
.gpicon-dashboard-filled:before {
    content: "\f120";
}
.gpicon-dashboard:before {
    content: "\f121";
}
.gpicon-delivery:before {
    content: "\f122";
}
.gpicon-dna:before {
    content: "\f123";
}
.gpicon-doc:before {
    content: "\f124";
}
.gpicon-double-back:before {
    content: "\f125";
}
.gpicon-double-forward:before {
    content: "\f126";
}
.gpicon-down:before {
    content: "\f127";
}
.gpicon-download:before {
    content: "\f128";
}
.gpicon-edit-sm:before {
    content: "\f129";
}
.gpicon-edit:before {
    content: "\f12a";
}
.gpicon-email:before {
    content: "\f12b";
}
.gpicon-exclamation:before {
    content: "\f12c";
}
.gpicon-expand:before {
    content: "\f12d";
}
.gpicon-external-link:before {
    content: "\f12e";
}
.gpicon-eye-slash:before {
    content: "\f12f";
}
.gpicon-eye:before {
    content: "\f130";
}
.gpicon-file:before {
    content: "\f131";
}
.gpicon-food:before {
    content: "\f132";
}
.gpicon-forward:before {
    content: "\f133";
}
.gpicon-habits:before {
    content: "\f134";
}
.gpicon-hamburger-2:before {
    content: "\f135";
}
.gpicon-hamburger:before {
    content: "\f136";
}
.gpicon-healthscore:before {
    content: "\f137";
}
.gpicon-home:before {
    content: "\f138";
}
.gpicon-hourglass:before {
    content: "\f139";
}
.gpicon-immune-system:before {
    content: "\f13a";
}
.gpicon-info-sm:before {
    content: "\f13b";
}
.gpicon-info:before {
    content: "\f13c";
}
.gpicon-left-chevron:before {
    content: "\f13d";
}
.gpicon-locked:before {
    content: "\f13e";
}
.gpicon-meal:before {
    content: "\f13f";
}
.gpicon-minus:before {
    content: "\f140";
}
.gpicon-nipt:before {
    content: "\f141";
}
.gpicon-notifications-filled:before {
    content: "\f142";
}
.gpicon-notifications:before {
    content: "\f143";
}
.gpicon-oral-microbiome:before {
    content: "\f144";
}
.gpicon-paypal:before {
    content: "\f145";
}
.gpicon-person-check:before {
    content: "\f146";
}
.gpicon-phone:before {
    content: "\f147";
}
.gpicon-pin:before {
    content: "\f148";
}
.gpicon-plus:before {
    content: "\f149";
}
.gpicon-polygenic-risk:before {
    content: "\f14a";
}
.gpicon-print:before {
    content: "\f14b";
}
.gpicon-private-cloud:before {
    content: "\f14c";
}
.gpicon-profile-filled:before {
    content: "\f14d";
}
.gpicon-profile:before {
    content: "\f14e";
}
.gpicon-recipe-generator:before {
    content: "\f14f";
}
.gpicon-recipe-stars:before {
    content: "\f150";
}
.gpicon-result:before {
    content: "\f151";
}
.gpicon-results-filled:before {
    content: "\f152";
}
.gpicon-results:before {
    content: "\f153";
}
.gpicon-search:before {
    content: "\f154";
}
.gpicon-settings:before {
    content: "\f155";
}
.gpicon-sport:before {
    content: "\f156";
}
.gpicon-sports-and-recreation:before {
    content: "\f157";
}
.gpicon-tick-mark:before {
    content: "\f158";
}
.gpicon-up:before {
    content: "\f159";
}
.gpicon-video:before {
    content: "\f15a";
}
.gpicon-web:before {
    content: "\f15b";
}
