/*------------------------------------
  CUSTOM STYLES FOR NGB POPOVER
------------------------------------*/
$border-radius: 0.75rem;
$shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.1);
$primary: #6a81f6;
$secondary: #60606c;

/* Common popover styles */
#gp-popover-common {
  width: 28rem;
  max-width: unset;
  border-color: white;
  z-index: 1030;
  border-radius: $border-radius;

  .popover-body {
    padding: 0;
  }

  .popover-content {
    padding: 1.5rem;
  }

  .popover-close {
    cursor: pointer;
    position: absolute;
    right: 1.4rem;
    top: 0.9rem;
  }

  &.bs-popover-top,
  &.bs-popover-bottom {
    .popover-arrow {
      width: 1.5rem;
      height: 0.75rem;
    }
  }

  &.bs-popover-left,
  &.bs-popover-right {
    .popover-arrow {
      width: 0.75rem;
      height: 1.5rem;
      margin-top: -0.75rem;
    }
  }

  &.bs-popover-top {
    .popover-arrow {
      bottom: -0.75rem;
      &::after,
      &::before {
        border-width: 0.75rem 0.75rem 0;
      }
    }
  }

  &.bs-popover-bottom {
    .popover-arrow {
      top: -0.75rem;
      &::after,
      &::before {
        border-width: 0rem 0.75rem 0.75rem 0.75rem;
      }
    }
  }

  &.bs-popover-left {
    .popover-arrow {
      right: -0.75rem;
      &::after,
      &::before {
        border-width: 0.75rem 0 0.75rem 0.75rem;
      }
    }
  }

  &.bs-popover-right {
    .popover-arrow {
      left: -0.75rem;
      &::after,
      &::before {
        border-width: 0.75rem 0.75rem 0.75rem 0;
      }
    }
  }

  @media (max-width: 767px) {
    max-width: calc(100% - 2rem);
  }
}

/* Basic gray popover (tooltip) */
.popover.popover-basic {
  @extend #gp-popover-common;

  max-width: 21rem;
  background-color: $secondary;
  border-color: $secondary;
  color: white;

  .popover-arrow::after,
  .popover-arrow::before {
    border-bottom-color: $secondary;
    border-top-color: $secondary;
  }

  &.bs-popover-top,
  &.bs-popover-bottom {
    .popover-arrow::after,
    .popover-arrow::before {
      border-bottom-color: $secondary;
      border-top-color: $secondary;
    }
  }

  &.bs-popover-left,
  &.bs-popover-right {
    .popover-arrow::after,
    .popover-arrow::before {
      border-left-color: $secondary;
      border-right-color: $secondary;
    }
  }
}

/* White popover with backdrop */
.popover.popover-large {
  @extend #gp-popover-common;

  width: 28rem;
  box-shadow: $shadow;
  border-color: white;

  .popover-content {
    padding: 2.5rem 1.5rem;
  }

  &.bs-popover-top,
  &.bs-popover-bottom {
    .popover-arrow::after,
    .popover-arrow::before {
      border-bottom-color: white;
      border-top-color: white;
    }
  }

  &.bs-popover-left,
  &.bs-popover-right {
    .popover-arrow::after,
    .popover-arrow::before {
      border-left-color: white;
      border-right-color: white;
    }
  }
}

/* White popover with no lip */
.popover.popover-no-lip {
  @extend #gp-popover-common;

  width: 28rem;
  box-shadow: $shadow;
  border-color: white;

  .popover-arrow {
    display: none;
  }

  .popover-content {
    padding: 1.5rem;

    .popover-close {
      display: none;
    }
  }

  @media (max-width: 767px) {
    .popover-content {
      padding: 2.5rem 1.5rem;

      .popover-close {
        display: block;
      }
    }
  }
}

/* Tour popover */
.popover.popover-tour {
  @extend #gp-popover-common;

  width: 21.5rem;
  box-shadow: $shadow;
  background-color: $primary;
  border-color: $primary;
  color: white;
  position: absolute;

  .popover-content {
    padding: 1.5rem;
    @media (max-width: 767px) {
      padding: 1rem;
    }
  }

  &.bs-popover-top,
  &.bs-popover-bottom {
    .popover-arrow::after,
    .popover-arrow::before {
      border-bottom-color: $primary;
      border-top-color: $primary;
    }
  }

  &.bs-popover-left,
  &.bs-popover-right {
    .popover-arrow::after,
    .popover-arrow::before {
      border-left-color: $primary;
      border-right-color: $primary;
    }
  }
}
