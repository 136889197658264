@use '@geneplanet/bootstrap/scss/shared/breakpoints';

@import '@geneplanet/bootstrap/scss/shared/colors';
// @import '~sass-google-fonts/src/GoogleFonts.scss';
// @include google-font('Open Sans', '300,400,500,600,700,800');
@import '@geneplanet/bootstrap/scss/bootstrap.scss';
@import 'swiper/css/bundle';
@import './_modals.scss';
@import '@geneplanet/bootstrap/scss/sprite.scss';
@import '@angular/cdk/overlay-prebuilt.css';
@import '@geneplanet/ngx-ui/scss/styles';

// temp. italian
html[lang='it'] {
  gps-cart-success.ng-star-inserted a {
    display: none;
  }

  gps-cart-success.ng-star-inserted img.mt-auto {
    margin-top: 0 !important;
  }

  gps-cart-success.ng-star-inserted div div {
    justify-content: center !important;
  }
}

// hide recaptcha badge
.grecaptcha-badge {
  visibility: hidden;
}

// remove outline on button focus
button:focus,
[role='button']:focus {
  outline: none !important;
}

// cart icon active color
app-root app-header .active > .gpicon-cart {
  color: $secondary !important;
}

// section inside container
.container > section {
  padding-top: 48px;
  padding-bottom: 48px;

  @include breakpoints.media-breakpoint-up(md) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

// recaptcha badge
.grecaptcha-badge {
  z-index: 1 !important;
  bottom: 40px !important;
}

// Hide captcha error "Could not connect to the reCAPTCHA service. Please check your internet connection and reload to get a reCAPTCHA challenge."
// This will hide body > div > div, which should not effect other elements (other have class on)
body > div:not([class]) > div:not([class]) {
  display: none;
}

/*
  Hack remove Tax from shop checkout
*/
.short-summary-wrapper ul.summary-list li:last-child {
  display: none !important;
}

// utility
.w-sm-50-md-100 {
  width: 100%;
  @media screen and (max-width: 767px) {
    width: 50%;
  }
}

.z-index-2 {
  position: relative;
  z-index: 2;
}

.wave-img-background {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 323px;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
}

.jumbotron-wave-container {
  position: relative;
  background: #fff;
  padding-left: 0;
  padding-right: 0;
}

.radius-12 {
  border-radius: 0.75rem !important;
}

.wave-stretch {
  background-position: center center !important;
  background-size: 100% 100% !important;
}

.mw-752 {
  max-width: 752px;
}

.mw-952 {
  max-width: 952px;
}

@media screen and (min-width: 1340px) {
  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-none {
    display: none !important;
  }
}

.w-80_w-64 {
  width: 80px;
}
.w-152_w-104 {
  width: 152px;
}
.w-252_w-128 {
  width: 252px;
}
.h-80_h-64 {
  height: 80px;
}

@media screen and (max-width: 767px) {
  .w-80_w-64 {
    width: 64px;
  }
  .w-152_w-104 {
    width: 104px;
  }
  .w-252_w-128 {
    width: 128px;
  }
  .h-80_h-64 {
    height: 64px;
  }
}

// Checkout success take whole screen below header.
@include breakpoints.media-breakpoint-down(md) {
  gps-cart-success {
    display: block;
    height: calc(100vh - 56px);
  }
}

// Above fixed pages and modals
.cdk-overlay-container {
  z-index: 1056;
}

// This is for Sendinblue Tiny icon. With this we fix overlay
// and prevent jumping when browser title/url bar shows/hides
.cdk-global-overlay-wrapper .cdk-overlay-fixed-bottom {
  position: fixed !important;
  bottom: 0;
  transition: bottom 0.3s ease-out;
  
  // Bottom offset is used to position element above sticky price
  &.tiny-bottom-offset {
    bottom: 82px;

    @include breakpoints.media-breakpoint-up(xl) {
      bottom: 96px;
    }

    @include breakpoints.media-breakpoint-up(xxl) {
      bottom: 106px;
    }
  }
}

// This class should exist in bootstrap, but it doesn't somehow. It existed in BS4
.bg-dark {
  background-color: $dark !important;
}
