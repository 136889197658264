@mixin spacing($prop, $abbrev, $infix, $i) {
  $value: #{$i/4}rem;

  .#{$abbrev}#{$infix}-#{$i*4} {
    #{$prop}: $value !important;
  }
  .#{$abbrev}t#{$infix}-#{$i*4},
  .#{$abbrev}y#{$infix}-#{$i*4} {
    #{$prop}-top: $value !important;
  }
  .#{$abbrev}r#{$infix}-#{$i*4},
  .#{$abbrev}x#{$infix}-#{$i*4} {
    #{$prop}-right: $value !important;
  }
  .#{$abbrev}b#{$infix}-#{$i*4},
  .#{$abbrev}y#{$infix}-#{$i*4} {
    #{$prop}-bottom: $value !important;
  }
  .#{$abbrev}l#{$infix}-#{$i*4},
  .#{$abbrev}x#{$infix}-#{$i*4} {
    #{$prop}-left: $value !important;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: gm, padding: gp) {
      @for $i from 0 through 50 {
        @include spacing($prop, $abbrev, $infix, $i);
      }
      // 2px spacing
      @include spacing($prop, $abbrev, $infix, 0.5);
    }
  }
}
