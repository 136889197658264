/*------------------------------------
  IMPORTANT! The following color variables are deprecated, remove usage from project when possible.
------------------------------------*/
$orange: #ff9f00;
$yellow: #ffcd03;
$teal: #17dddb;
$light-teal: #e2fbfb;
$label-red: #ff6199;
$light-red: #ff9bbe;
$lighter-red: #ffe6ee;
$light-info: #80dcdd;

/*------------------------------------
  Colors Design System
------------------------------------*/
$white: #ffffff;
$danger: #ff397f;
$dark: #2c3355;

/* Prime */
$info: #00b9bb;

/* Accent */
$primary: #6a81f6;

/* Type */
$body-color: #2c3355;
$secondary: #616680;
$text-muted: #9599aa;
$text-transparent-1: rgba(255, 255, 255, 0.7);
$text-transparent-2: rgba(255, 255, 255, 0.5);

/* Gray */
$gray-1: #f9f9fe;
$gray-2: #efeff4;
$gray-3: #e5e5ea;
$gray-4: #c7c7d2;
$gray-5: #a3a3b2;
$gray-6: #60606c;
$gray-8: #212130;

/* Background */
$bg-lightblue: #fafbff;
$bg-lightblue-2: #f4f7ff;
$bg-transparent-1: rgba(255, 255, 255, 0.85);
$bg-transparent-2: rgba(255, 255, 255, 0.75);
$bg-transparent-3: rgba(255, 255, 255, 0.5);
$bg-transparent-4: rgba(255, 255, 255, 0.35);

/* Modal */
$backdrop-modal: rgba(0, 0, 0, 0.25);

/* Line chart */
$bg-line-chart-optimal: rgba(51, 199, 201, 0.075);

/* Nav bar */
$bg-nav-bar-title-bar: rgba(244, 247, 255, 0.8);
$bg-nav-bar-badge: #ff6199;
$text-nav-bar-active: #283981;
$stroke-nav-bar-icon: #6e7490;

/* Results */
$level-1: #ff6199;
$level-2: #ffb233;
$level-3: #ffd735;
$level-4: #45e4e2;
$level-5: #33c7c9;
$level-6: #889af8;

/* Label */
$bg-label: rgba(136, 154, 248, 0.15);
$text-label: #a3a3b2;
$border-label: #e5e5ea;
$text-label-red: #ff6199;
$border-label-red: #ff9bbe;
$text-label-blue: #889af8;
$border-label-blue: #b4c0fb;
$text-label-yellow: #ffb233;
$border-label-yellow: #ffcf80;
$text-label-green: #33c7c9;
$border-label-green: #7adadc;

/* Highlights */
$text-highlights-important: #ff397f;
$bg-highlights-important: #fff8fa;
$border-highlights-important:#ff9bbe;
$text-highlights-warning: #ff9f00;
$bg-highlights-warning: #fffcf7;
$border-highlights-warning:#ffcf80;
$text-highlights-primary: #6a81f6;
$bg-highlights-primary: #fafbfe;
$border-highlights-primary:#b4c0fb;
$text-highlights-confirmation: #00b9bb;
$bg-highlights-confirmation: #f5fcfc;
$border-highlights-confirmation:#7adadc;

/* Inputs */
$text-input-focused: #8fa1f8;
$border-input-focused: #9eadf9;
$text-input-error: #ff6b9f;
$border-input-error: #ff7eab;
$text-input-confirmation: #33c7c9;

/* Graphics */
$bg-graphics-blue-1: #2c3583;
$bg-graphics-blue-1: #b4c0fb;
$bg-graphics-blue-3: #eceffe;
$bg-graphics-red-1: #ff9bbe;
$bg-graphics-red-2: #ffe6ee;
$bg-graphics-orange-1: #ffcf80;
$bg-graphics-orange-2: #fff3df;
$bg-graphics-yellow-1: #ffe681;
$bg-graphics-yellow-2: #fff9df;
$bg-graphics-green-1: #8beeed;
$bg-graphics-green-2: #e2fbfb;
$bg-graphics-teal-1: #80dcdd;
$bg-graphics-teal-2: #d9f5f5;
