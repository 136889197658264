/*------------------------------------
  COMMON
------------------------------------*/

$animationSpeed: 200ms;

.top-modal-header-mobile {
  background: linear-gradient(white, rgba(255, 255, 255, 0));
}

.modal.modal-static .modal-dialog {
  transform: none;
}

.modal-content {
  border: 0;
  border-radius: 0.75rem;
  overflow: hidden;
}

.modal-title {
  font-weight: 800;
}

.modal-fixed-height {
  max-height: 87.3vh;
  overflow-y: auto;
}

.modal-backdrop.show {
  opacity: 0.25;
}

@include media-breakpoint-down(md) {
  .modal-dialog {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
    margin: 0 !important;
    overflow-y: initial !important;
    min-width: 100%;
    max-width: 100%;
  }

  .modal-content {
    border: 0.5rem solid transparent;
    background-color: transparent;

    & > * {
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      background-color: #fff;
      align-items: stretch;

      .modal-header,
      .modal-footer {
        flex-grow: 0;
      }
    }
  }
}

/*------------------------------------
  BASIC MODAL
------------------------------------*/

.basic-modal-backdrop {
  @include media-breakpoint-down(md) {
    animation: backdropFadein $animationSpeed;
  }
}

.basic-modal {
  .modal-dialog {
    max-width: 43.5rem;
  }

  .modal-content {
    position: fixed;
    bottom: 0;
    border: 0;
    max-height: calc(100vh - 1rem);
    animation: menuSlideUp $animationSpeed;

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 3rem;
      z-index: 1;
    }

    &:before {
      top: 0;
      background: linear-gradient(white, rgba(255, 255, 255, 0));
    }

    &:after {
      bottom: 0;
      background: linear-gradient(rgba(255, 255, 255, 0), white);
    }
  }

  .modal-header {
    height: 0;
    padding: 0;
    z-index: 1;
    align-items: start;

    .btn-close {
      background: transparent;
      width: unset;
      height: unset;
      margin: 0 0 0 auto;
      padding: 1rem;
      z-index: 10;
      box-shadow: none;
    }
  }

  .modal-body {
    overflow: auto;
    max-height: 45rem;
    padding: 3rem 1.5rem;

    @media (max-height: 60rem) {
      max-height: 75vh;
    }
  }

  @include media-breakpoint-down(md) {
    .modal-content {
      border-radius: 0.75rem 0.75rem 0 0;
    }
  }

  @include media-breakpoint-up(sm) {
    .modal-body {
      padding: 4rem 4.5rem;
    }

    .modal-header {
      height: 0;
      padding: 0;
      .btn-close {
        padding: 1.5rem;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .modal-dialog {
      display: flex;
      align-items: center;
      margin: 0 auto;
      height: 100vh;
    }

    .modal-content {
      position: relative;
      animation: none;

      &:before,
      &:after {
        height: 3.5rem;
      }
    }
  }
}

/*------------------------------------
  FULLSCREEN MODAL
------------------------------------*/

.fullscreen-modal {
  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
  }

  .modal-dialog {
    position: fixed;
    margin: 0;
    min-width: 100%;
    height: 100%;
    padding: 0;
  }

  .modal-content {
    overflow-y: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 0;
    background: #fff;

    & > * {
      overflow: initial;
      height: 100%;
    }
  }
}

/*------------------------------------
  SMALL MODAL
------------------------------------*/

.small-modal {
  .modal-dialog {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    margin: 0 !important;
    min-width: unset !important;
    max-width: 37.25rem !important;
    height: fit-content !important;
  }

  .modal-content {
    max-height: 75vh;
    overflow-y: auto;
  }

  .modal-body {
    padding: 0 4.5rem 3.5rem 4.5rem;
  }

  @include media-breakpoint-only(xs) {
    .modal-dialog {
      max-width: 21.5rem !important;
    }
  }

  @include media-breakpoint-down(xl) {
    .modal-dialog {
      width: 95% !important;
    }

    .modal-content {
      width: 100%;
      max-height: calc(100vh - 1rem);
      padding-bottom: 0;
      border: 0;
    }

    .modal-body {
      padding: 0 1.5rem 2rem 1.5rem;
    }
  }
}

/*------------------------------------
  DIALOG MODAL (ngx-ui / ModalDialogComponent)
------------------------------------*/

.dialog {
  .modal-dialog {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -55%) !important;
  }

  .modal-header {
    height: 3rem;
    padding: 0;
  }

  .modal-header .btn-close {
    background: transparent;
    width: unset;
    height: unset;
    position: absolute;
    right: 1rem;
    top: 1rem;
    padding: 0;
    box-shadow: none;
  }

  .caution {
    width: 5rem;
  }

  .modal-content {
    width: 596px;
    max-height: 75vh;
    overflow-y: auto;
  }

  @include media-breakpoint-down(xl) {
    .modal-content {
      width: 100%;
      max-height: calc(100vh - 1rem);
      padding-bottom: 3rem;
      border: 0;
      animation-name: slideUp;
      animation-duration: 0.3s;
      animation-timing-function: ease-in-out;
    }

    .modal-dialog {
      position: fixed !important;
      top: 1rem !important;
      left: unset !important;
      transform: unset !important;
    }
  }
}

/*------------------------------------
  MOBILE FOOTER MODAL
------------------------------------*/

.mobile-footer-modal-backdrop {
  z-index: 1049 !important;
  animation: backdropFadein $animationSpeed;
}

.mobile-footer-modal {
  width: 100%;
  height: 100%;
  z-index: 1049;

  .modal-dialog {
    margin: 0;
    padding: 0;
    height: calc(100% - 65px);
    width: 100%;
    max-width: 100%;
    overflow: hidden;

    .modal-content {
      position: fixed;
      bottom: 65px;
      max-height: calc(100vh - 5rem);
      border: none;
      border-radius: 0.75rem 0.75rem 0 0;
      width: 100%;
      animation: menuSlideUp $animationSpeed;
      & > * {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    .modal-body {
      max-height: 70vh;
    }
  }
}

/* ANALYSES CARD MODAL */
.analyses-card-modal {
  ul {
    padding-left: 0;
    li {
      padding-left: 0.5rem;
      &::marker {
        content: '•';
      }
    }
  }
}

@keyframes menuSlideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes menuSlideLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes backdropFadein {
  from {
    background-color: transparent;
  }
  to {
    background-color: black;
  }
}
