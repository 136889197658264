@use 'shared/colors.scss';

$rfs-breakpoint: 1920px;
$typography-breakpoint: 1919px !default;

@import 'rfs/scss.scss';

/*------------------------------------
  Fonts according to the Design
------------------------------------*/
$h1-font-size: 64px;
$h2-font-size: 48px;
$h3-font-size: 32px;
$h4-font-size: 24px;
$h5-font-size: 22px;
$h6-font-size: 20px;

/* Headline */
.h1-extrabold {
  @include font-size(64px !important);
  font-weight: 800 !important;
  line-height: 80px !important;
  letter-spacing: -1.5px !important;
}
.h2-extrabold {
  @include font-size(48px !important);
  font-weight: 800 !important;
  line-height: 64px !important;
  letter-spacing: -1px !important;
}
.h3-extrabold {
  @include font-size(32px !important);
  font-weight: 800 !important;
  line-height: 48px !important;
}
.h3-bold {
  @include font-size(32px !important);
  font-weight: bold !important;
  line-height: 48px !important;
}
.h4-extrabold {
  @include font-size(24px !important);
  font-weight: 800 !important;
  line-height: 40px !important;
}
.h4-bold {
  @include font-size(24px !important);
  font-weight: bold !important;
  line-height: 40px !important;
}
.h5-bold {
  @include font-size(22px !important);
  font-weight: bold !important;
  line-height: 22px !important;
  color: $gray-5 !important;
}
.h6-bold {
  @include font-size(20px !important);
  font-weight: bold !important;
  line-height: 32px !important;
}
.h7-bold {
  @include font-size(17px !important);
  font-weight: bold !important;
  line-height: 32px !important;
}

/* Result */
.r1-extrabold {
  @include font-size(48px !important);
  line-height: 64px !important;
  font-weight: 800 !important;
}
.r2-extrabold {
  @include font-size(24px !important);
  line-height: 32px !important;
  font-weight: 800 !important;
}
.r3-regular {
  @include font-size(20px !important);
  line-height: 32px !important;
  font-weight: normal !important;
}
.r4-semibold {
  @include font-size(17px !important);
  line-height: 24px !important;
  font-weight: 600 !important;
}
.r4-regular {
  @include font-size(17px !important);
  line-height: 24px !important;
  font-weight: normal !important;
}
.r5-semibold {
  @include font-size(15px !important);
  line-height: 24px !important;
  font-weight: 600 !important;
}
.r6-semibold {
  @include font-size(13px !important);
  line-height: 24px !important;
  font-weight: 600 !important;
}
.r7-regular {
  @include font-size(13px !important);
  line-height: 16px !important;
  font-weight: normal !important;
}
.r8-regular {
  @include font-size(8px !important);
  line-height: 16px !important;
  font-weight: normal !important;
}

/* Body */
.p1-semibold {
  @include font-size(24px !important);
  font-weight: 600 !important;
  line-height: 48px !important;
}
.p1,
.p1-regular {
  @include font-size(24px !important);
  font-weight: normal !important;
  line-height: 48px !important;
}
.p2-semibold {
  @include font-size(20px !important);
  font-weight: 600 !important;
  line-height: 40px !important;
}
.p2,
.p2-regular {
  @include font-size(20px !important);
  font-weight: normal !important;
  line-height: 40px !important;
}
.p3-semibold {
  @include font-size(17px !important);
  font-weight: 600 !important;
  line-height: 32px !important;
}
.p3,
.p3-regular {
  @include font-size(17px !important);
  font-weight: normal !important;
  line-height: 32px !important;
}
.p4-semibold {
  @include font-size(15px !important);
  font-weight: 600 !important;
  line-height: 24px !important;
}
.p4,
.p4-regular {
  @include font-size(15px !important);
  font-weight: normal !important;
  line-height: 24px !important;
}
.p5-semibold {
  @include font-size(13px !important);
  font-weight: 600 !important;
  line-height: 16px !important;
}
.p5,
.p5-regular {
  @include font-size(13px !important);
  font-weight: normal !important;
  line-height: 16px !important;
}
.p6-semibold {
  @include font-size(10px !important);
  font-weight: 600 !important;
  line-height: 16px !important;
}
.p6,
.p6-regular {
  @include font-size(10px !important);
  font-weight: normal !important;
  line-height: 16px !important;
}

/* Caption */
.c1-bold {
  @include font-size(20px !important);
  font-weight: bold !important;
  line-height: 32px !important;
}
.c2-bold {
  @include font-size(17px !important);
  font-weight: bold !important;
  line-height: 32px !important;
}
.c2-semibold {
  @include font-size(17px !important);
  font-weight: 600 !important;
  line-height: 32px !important;
}
.c3-bold {
  @include font-size(15px !important);
  font-weight: bold !important;
  line-height: 24px !important;
}
.c3-semibold {
  @include font-size(15px !important);
  font-weight: 600 !important;
  line-height: 24px !important;
}
.c4-bold {
  @include font-size(13px !important);
  font-weight: bold !important;
  line-height: 24px !important;
}
.c4-semibold {
  @include font-size(13px !important);
  font-weight: 600 !important;
  line-height: 24px !important;
}
.c5-bold {
  @include font-size(8px !important);
  font-weight: bold !important;
  line-height: 16px !important;
}
.c5-semibold {
  @include font-size(8px !important);
  font-weight: 600 !important;
  line-height: 16px !important;
}

/* Quote */
.q-light {
  @include font-size(32px !important);
  font-weight: 300 !important;
  line-height: 64px !important;
}

/* Button */
.b1-semibold {
  @include font-size(24px !important);
  line-height: 40px !important;
  font-weight: 600 !important;
}
.b2-semibold {
  @include font-size(17px !important);
  line-height: 32px !important;
  font-weight: 600 !important;
}
.b3-semibold {
  @include font-size(15px !important);
  line-height: 24px !important;
  font-weight: 600 !important;
}
.b4-regular {
  @include font-size(10px !important);
  line-height: 16px !important;
  font-weight: normal !important;
}

/* Input */
.i1-regular {
  @include font-size(17px !important);
  line-height: 24px !important;
  font-weight: normal !important;
}
.i2-regular {
  @include font-size(13px !important);
  line-height: 16px !important;
  font-weight: normal !important;
}

/* Row */
.rv1-extrabold {
  font-size: 24px !important;
  font-weight: 800 !important;
  line-height: 32px !important;
}

.rv2-bold {
  font-size: 20px !important;
  font-weight: bold !important;
  line-height: 32px !important;
}

.rt1-regular {
  font-size: 17px !important;
  font-weight: normal !important;
  line-height: 24px !important;
}

.rt2-regular {
  font-size: 15px !important;
  font-weight: normal !important;
  line-height: 24px !important;
}

.rt2-semibold {
  font-size: 15px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
}

.rs1-regular {
  font-size: 17px !important;
  font-weight: normal !important;
  line-height: 24px !important;
}

.rs2-regular {
  font-size: 15px !important;
  font-weight: normal !important;
  line-height: 24px !important;
}

.rd1-regular {
  font-size: 15px !important;
  font-weight: normal !important;
  line-height: 24px !important;
}

.rl1-regular {
  font-size: 13px !important;
  font-weight: normal !important;
  line-height: 16px !important;
}

.rl2-regular {
  font-size: 10px !important;
  font-weight: normal !important;
  line-height: 20px !important;
}

.rl3-regular {
  font-size: 9px !important;
  font-weight: normal !important;
  line-height: 16px !important;
  letter-spacing: 0.35px !important;
}

/* Label */
.l1-regular {
  font-size: 13px !important;
  font-weight: normal !important;
  line-height: 16px !important;
}

.l1-semibold {
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
}

.l2-semibold {
  font-size: 10px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
}

/* Card */
.ch1-extrabold {
  font-size: 22px !important;
  font-weight: 800 !important;
  line-height: 32px !important;
}

.ct1-bold {
  font-size: 28px !important;
  font-weight: bold !important;
  line-height: 38px !important;
  letter-spacing: -0.15px !important;
}

.cs1-bold {
  font-size: 15px !important;
  font-weight: bold !important;
  line-height: 24px !important;
}

.cv1-bold {
  font-size: 20px !important;
  font-weight: bold !important;
  line-height: 32px !important;
}

.ct2-semibold {
  font-size: 17px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
}

.ct2-regular {
  font-size: 17px !important;
  font-weight: normal !important;
  line-height: 24px !important;
}

.cd-regular {
  font-size: 15px !important;
  font-weight: normal !important;
  line-height: 24px !important;
}

.ctg1-semibold {
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
}

.cl1-regular {
  font-size: 13px !important;
  font-weight: normal !important;
  line-height: 16px !important;
}

.cl2-regular {
  font-size: 10px !important;
  font-weight: normal !important;
  line-height: 16px !important;
}

/* Value */
.v1-extrabold {
  font-size: 32px !important;
  font-weight: 800 !important;
  line-height: 40px !important;
}

.v2-extrabold {
  font-size: 28px !important;
  font-weight: 800 !important;
  line-height: 40px !important;
}

/* Table */
.th1-bold {
  font-size: 17px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
}

.tv1-extrabold {
  font-size: 26px !important;
  font-weight: 800 !important;
  line-height: 40px !important;
}

.tv2-extrabold {
  font-size: 22px !important;
  font-weight: 800 !important;
  line-height: 32px !important;
}

.tv2-bold {
  font-size: 15px !important;
  font-weight: 800 !important;
  line-height: 24px !important;
}

.tv4-bold {
  font-size: 17px !important;
  font-weight: 700 !important;
  line-height: 32px !important;
}

.ts1-regular {
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
}

.tt1-semibold {
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
}

.tt1-regular {
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
}

.td1-regular {
  font-size: 10px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
}

.tl1-bold {
  font-size: 9px !important;
  font-weight: 700 !important;
  line-height: 16px !important;
}

/* Font weight definitions */
@each $size in (300, 400, 500, 600, 700, 800, 900) {
  .font-weight-#{$size} {
    font-weight: $size !important;
  }
}

/* SM screen */
@media screen and (max-width: $typography-breakpoint) {
  /* 414px */
  /* Headline S */
  .h1-extrabold {
    font-size: 34px !important;
    font-weight: 800 !important;
    line-height: 56px !important;
    letter-spacing: 0px !important;
  }
  .h2-extrabold {
    font-size: 24px !important;
    font-weight: 800 !important;
    line-height: 32px !important;
    letter-spacing: 0px !important;
  }
  .h3-extrabold {
    font-size: 22px !important;
    font-weight: 800 !important;
    line-height: 32px !important;
  }
  .h3-bold {
    font-size: 20px !important;
    font-weight: bold !important;
    line-height: 32px !important;
  }
  .h4-extrabold {
    font-size: 20px !important;
    font-weight: 800 !important;
    line-height: 32px !important;
  }
  .h4-bold {
    font-size: 20px !important;
    font-weight: bold !important;
    line-height: 32px !important;
    letter-spacing: -0.25px !important;
  }
  .h5-bold {
    font-size: 20px !important;
    font-weight: bold !important;
    line-height: 24px !important;
    color: $gray-5 !important;
  }
  .h6-bold {
    font-size: 17px !important;
    font-weight: bold !important;
    line-height: 24px !important;
  }
  .h7-bold {
    font-size: 15px !important;
    font-weight: bold !important;
    line-height: 24px !important;
  }

  /* Body S */
  .p1-semibold {
    font-size: 17px !important;
    font-weight: 600 !important;
    line-height: 32px !important;
  }
  .p1,
  .p1-regular {
    font-size: 17px !important;
    font-weight: normal !important;
    line-height: 32px !important;
  }
  .p2-semibold {
    font-size: 15px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
  }
  .p2,
  .p2-regular {
    font-size: 15px !important;
    font-weight: normal !important;
    line-height: 24px !important;
  }
  .p3-semibold {
    font-size: 13px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
  }
  .p3,
  .p3-regular {
    font-size: 13px !important;
    font-weight: normal !important;
    line-height: 24px !important;
  }
  .p4-semibold {
    font-size: 13px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
  }
  .p4,
  .p4-regular {
    font-size: 13px !important;
    font-weight: normal !important;
    line-height: 24px !important;
  }
  .p5-semibold {
    font-size: 10px !important;
    font-weight: 600 !important;
    line-height: 16px !important;
  }
  .p5,
  .p5-regular {
    font-size: 10px !important;
    font-weight: normal !important;
    line-height: 16px !important;
  }
  .p6-semibold {
    font-size: 8px !important;
    font-weight: 600 !important;
    line-height: 16px !important;
  }
  .p6,
  .p6-regular {
    font-size: 8px !important;
    font-weight: normal !important;
    line-height: 16px !important;
  }

  /* Quote S */
  .q-light {
    font-size: 20px !important;
    font-weight: 300 !important;
    line-height: 32px !important;
  }

  /* Caption S */
  .c1-bold {
    font-size: 20px !important;
    font-weight: bold !important;
    line-height: 32px !important;
  }
  .c2-bold {
    font-size: 17px !important;
    font-weight: bold !important;
    line-height: 32px !important;
  }
  .c2-semibold {
    font-size: 17px !important;
    font-weight: 600 !important;
    line-height: 32px !important;
  }
  .c3-bold {
    font-size: 15px !important;
    font-weight: bold !important;
    line-height: 24px !important;
  }
  .c3-semibold {
    font-size: 15px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
  }
  .c4-bold {
    font-size: 13px !important;
    font-weight: bold !important;
    line-height: 24px !important;
  }
  .c4-semibold {
    font-size: 13px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
  }
  .c5-bold {
    font-size: 8px !important;
    font-weight: bold !important;
    line-height: 16px !important;
  }
  .c5-semibold {
    font-size: 8px !important;
    font-weight: 600 !important;
    line-height: 16px !important;
  }

  /* Result S */
  .r1-extrabold {
    font-size: 34px !important;
    line-height: 40px !important;
    font-weight: 800 !important;
  }
  .r2-extrabold {
    font-size: 24px !important;
    line-height: 32px !important;
    font-weight: 800 !important;
  }
  .r3-regular {
    font-size: 17px !important;
    line-height: 24px !important;
    font-weight: normal !important;
  }
  .r4-semibold {
    font-size: 15px !important;
    line-height: 24px !important;
    font-weight: 600 !important;
  }
  .r4-regular {
    font-size: 15px !important;
    line-height: 24px !important;
    font-weight: normal !important;
  }
  .r5-semibold {
    font-size: 15px !important;
    line-height: 24px !important;
    font-weight: 600 !important;
  }
  .r6-semibold {
    font-size: 13px !important;
    line-height: 24px !important;
    font-weight: 600 !important;
  }
  .r7-regular {
    font-size: 13px !important;
    line-height: 16px !important;
    font-weight: normal !important;
  }
  .r8-regular {
    font-size: 8px !important;
    line-height: 16px !important;
    font-weight: normal !important;
  }

  /* Input S */
  .i1-regular {
    @include font-size(17px !important);
    line-height: 24px !important;
    font-weight: normal !important;
  }
  .i2-regular {
    @include font-size(13px !important);
    line-height: 16px !important;
    font-weight: normal !important;
  }

  /* Button S */
  .b1-semibold {
    @include font-size(20px !important);
    line-height: 32px !important;
    font-weight: 600 !important;
  }
  .b2-semibold {
    @include font-size(17px !important);
    line-height: 32px !important;
    font-weight: 600 !important;
  }
  .b3-semibold {
    @include font-size(15px !important);
    line-height: 24px !important;
    font-weight: 600 !important;
  }
  .b4-regular {
    @include font-size(10px !important);
    line-height: 16px !important;
    font-weight: normal !important;
  }

  /* Card S */
  .ct2-semibold {
    font-size: 15px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
  }
  .ct2-regular {
    font-size: 15px !important;
    font-weight: normal !important;
    line-height: 24px !important;
  }
  .cd-regular {
    font-size: 13px !important;
    font-weight: normal !important;
    line-height: 16px !important;
  }

  /* Table S */
  .th1-bold {
    font-size: 12px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
  }

  .tv1-extrabold {
    font-size: 20px !important;
    font-weight: 800 !important;
    line-height: 28px !important;
  }

  .tv2-bold {
    font-size: 9px !important;
    font-weight: 800 !important;
    line-height: 16px !important;
  }

  .ts1-regular {
    font-size: 10px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
  }

  .tt1-semibold {
    font-size: 10px !important;
    font-weight: 600 !important;
    line-height: 16px !important;
  }

  .tt1-regular {
    font-size: 10px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
  }

  .td1-regular {
    font-size: 8px !important;
    font-weight: 400 !important;
    line-height: 12px !important;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
