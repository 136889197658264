@use '@geneplanet/bootstrap/scss/shared/colors';
@use '@geneplanet/bootstrap/scss/shared/breakpoints';

$small-line-height: 1rem;

// Core styles that enable monitoring autofill state of inputs.
@keyframes gpu-input-autofill-start {
  from {
    background: transparent;
  }
  to {
    background: transparent;
  }
}
@keyframes gpu-input-autofill-end {
  from {
    background: transparent;
  }
  to {
    background: transparent;
  }
}
.gpu-input:-webkit-autofill {
  // Since Chrome 80 we need a 1ms delay, or the animationstart event won't fire.
  animation: gpu-input-autofill-start 0s 1ms;
}
.gpu-input:not(:-webkit-autofill) {
  // Since Chrome 80 we need a 1ms delay, or the animationstart event won't fire.
  animation: gpu-input-autofill-end 0s 1ms;
}

// Datepicker
ngb-datepicker {
  border: 0 !important;
  border-radius: 12px !important;
  box-shadow: 0px 10px 20px 5px rgba(78, 95, 124, 0.1);

  &.form-field-datepicker {
    margin-top: 0.625rem !important;
    left: -1rem !important;
  }

  .ngb-dp-header {
    background: colors.$bg-lightblue;
    padding-top: 0.5rem;
    border-radius: 12px 12px 0 0;
  }

  ngb-datepicker-navigation {
    justify-content: center;

    .ngb-dp-arrow,
    .ngb-dp-navigation-select {
      display: contents;
    }

    .ngb-dp-navigation-select {
      flex: 1 1 15rem;
    }

    .ngb-dp-arrow-btn {
      margin: 0;
      padding: 0.5rem;
    }

    .ngb-dp-navigation-chevron {
      border-color: colors.$gray-4;
      border-radius: 1px;
      border-width: 0.15em 0.15em 0 0 !important;
      width: 0.6em !important;
      height: 0.6em !important;
    }
  }

  .custom-select {
    height: 2rem;
    max-width: 130px;
    font-size: $input-font-size;
    line-height: $input-line-height;
    color: colors.$secondary;
    padding-left: 1.5rem;
    margin: 0.5rem;
    background: url("data:image/svg+xml,%3Csvg width='16' height='8' viewBox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.3651 0.772887C11.7553 0.381992 12.3949 0.387812 12.7858 0.777965C13.1767 1.16812 13.1837 1.8077 12.7936 2.1986L7.71277 7.28906C7.32235 7.68022 6.68864 7.68052 6.29786 7.28971L1.20708 2.19861C0.816573 1.80807 0.82302 1.16848 1.21356 0.777965C1.60409 0.387453 2.24369 0.381046 2.6342 0.771582L6.29655 4.43417C6.68733 4.82498 7.32103 4.82469 7.71146 4.43352L11.3651 0.772887Z' fill='%23C7C7D2'/%3E%3C/svg%3E")
      no-repeat 90%;
    border-radius: 8px;
    background-color: colors.$white;

    @include breakpoints.media-breakpoint-down(md) {
      max-width: 107px;
    }
  }

  .ngb-dp-month {
    width: 100%;
    padding-bottom: 0.25rem;
  }

  ngb-datepicker-month {
    width: 100%;
  }

  .ngb-dp-week {
    display: flex;
    justify-content: center;

    &.ngb-dp-weekdays {
      background: colors.$bg-lightblue;
      border: 0;
      margin-bottom: 0.25rem;
    }
  }

  .ngb-dp-weekday,
  .ngb-dp-day {
    height: auto;
    font-style: normal !important;
    font-size: $small-font-size;
    line-height: $small-line-height;
  }

  .ngb-dp-weekday {
    width: 2.5rem;
    color: colors.$primary !important;
    padding: 0.25rem 0.75rem 0.75rem 0.75rem;
  }

  .ngb-dp-day {
    width: 2.5rem;
    height: 2rem;
    padding: 0.25rem 0.5rem;
    outline: none;

    &:hover .btn-light,
    .btn-light:hover {
      background: colors.$gray-1 !important;
    }
  }

  .btn-light {
    border-radius: 100px;
    height: 1.5rem;
    width: 1.5rem;
    line-height: 1.5rem;
    color: colors.$secondary;

    &.bg-primary {
      background: colors.$bg-graphics-blue-3 !important;
      color: colors.$primary !important;
    }
  }
}

// Loading spinner overlay backdrop
.gpu-loading-spinner-overlay-backdrop {
  backdrop-filter: blur(5px);
  transition: none;

  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background: rgba(255, 255, 255, 0.5);
  }
}
