%svg-common {
    background: url("../assets/sprites/sprites-fdd6ca9c.svg") no-repeat;
}

.svg-al-flag {
    @extend %svg-common;
    background-position: 0 0;
}

.svg-al-flag-dims {
    width: 32px;
    height: 32px;
}

.svg-al-flag-sm {
    @extend %svg-common;
    background-position: 0 2.0151133501259446%;
}

.svg-al-flag-sm-dims {
    width: 24px;
    height: 24px;
}

.svg-app-store {
    @extend %svg-common;
    background-position: 0 3.582853486884197%;
}

.svg-app-store-dims {
    width: 160px;
    height: 49px;
}

.svg-ch-flag {
    @extend %svg-common;
    background-position: 0 6.6455696202531644%;
}

.svg-ch-flag-dims {
    width: 32px;
    height: 32px;
}

.svg-ch-flag-sm {
    @extend %svg-common;
    background-position: 0 8.6272040302267%;
}

.svg-ch-flag-sm-dims {
    width: 24px;
    height: 24px;
}

.svg-co-flag {
    @extend %svg-common;
    background-position: 0 10.189873417721518%;
}

.svg-co-flag-dims {
    width: 32px;
    height: 32px;
}

.svg-co-flag-sm {
    @extend %svg-common;
    background-position: 0 12.153652392947103%;
}

.svg-co-flag-sm-dims {
    width: 24px;
    height: 24px;
}

.svg-cookies {
    @extend %svg-common;
    background-position: 0 14.164490861618798%;
}

.svg-cookies-dims {
    width: 81px;
    height: 80px;
}

.svg-dhl {
    @extend %svg-common;
    background-position: 0 18.714555765595463%;
}

.svg-dhl-dims {
    width: 99px;
    height: 25px;
}

.svg-ekp-logo {
    @extend %svg-common;
    background-position: 0 20.81447963800905%;
}

.svg-ekp-logo-dims {
    width: 161px;
    height: 65px;
}

.svg-en-flag {
    @extend %svg-common;
    background-position: 0 24.49367088607595%;
}

.svg-en-flag-dims {
    width: 32px;
    height: 32px;
}

.svg-en-flag-sm {
    @extend %svg-common;
    background-position: 0 26.385390428211586%;
}

.svg-en-flag-sm-dims {
    width: 24px;
    height: 24px;
}

.svg-eu-flag {
    @extend %svg-common;
    background-position: 0 28.037974683544302%;
}

.svg-eu-flag-dims {
    width: 32px;
    height: 32px;
}

.svg-eu-flag-sm {
    @extend %svg-common;
    background-position: 0 29.91183879093199%;
}

.svg-eu-flag-sm-dims {
    width: 24px;
    height: 24px;
}

.svg-facebook-color-icn {
    @extend %svg-common;
    background-position: 0 31.74300254452926%;
}

.svg-facebook-color-icn-dims {
    width: 41px;
    height: 40px;
}

.svg-gl-flag {
    @extend %svg-common;
    background-position: 0 34.11392405063291%;
}

.svg-gl-flag-dims {
    width: 32px;
    height: 32px;
}

.svg-gl-flag-sm {
    @extend %svg-common;
    background-position: 0 35.957178841309826%;
}

.svg-gl-flag-sm-dims {
    width: 24px;
    height: 24px;
}

.svg-google-play {
    @extend %svg-common;
    background-position: 0 38.067818298144594%;
}

.svg-google-play-dims {
    width: 162px;
    height: 49px;
}

.svg-hr-flag {
    @extend %svg-common;
    background-position: 0 40.75949367088607%;
}

.svg-hr-flag-dims {
    width: 32px;
    height: 32px;
}

.svg-hr-flag-sm {
    @extend %svg-common;
    background-position: 0 42.56926952141058%;
}

.svg-hr-flag-sm-dims {
    width: 24px;
    height: 24px;
}

.svg-instagram-color-icn {
    @extend %svg-common;
    background-position: 0 44.529262086513995%;
}

.svg-instagram-color-icn-dims {
    width: 41px;
    height: 40px;
}

.svg-linkedin-color-icn {
    @extend %svg-common;
    background-position: 0 47.07379134860051%;
}

.svg-linkedin-color-icn-dims {
    width: 41px;
    height: 40px;
}

.svg-mobile-app-qr-code {
    @extend %svg-common;
    background-position: 0 52.56064690026954%;
}

.svg-mobile-app-qr-code-dims {
    width: 128px;
    height: 128px;
}

.svg-mx-flag {
    @extend %svg-common;
    background-position: 0 57.46835443037975%;
}

.svg-mx-flag-dims {
    width: 32px;
    height: 32px;
}

.svg-mx-flag-sm {
    @extend %svg-common;
    background-position: 0 59.193954659949625%;
}

.svg-mx-flag-sm-dims {
    width: 24px;
    height: 24px;
}

.svg-newsletter-icn {
    @extend %svg-common;
    background-position: 0 61.32315521628499%;
}

.svg-newsletter-icn-dims {
    width: 41px;
    height: 40px;
}

.svg-pa-flag {
    @extend %svg-common;
    background-position: 0 63.54430379746835%;
}

.svg-pa-flag-dims {
    width: 32px;
    height: 32px;
}

.svg-pa-flag-sm {
    @extend %svg-common;
    background-position: 0 65.23929471032746%;
}

.svg-pa-flag-sm-dims {
    width: 24px;
    height: 24px;
}

.svg-pl-flag {
    @extend %svg-common;
    background-position: 0 67.0886075949367%;
}

.svg-pl-flag-dims {
    width: 32px;
    height: 32px;
}

.svg-pl-flag-sm {
    @extend %svg-common;
    background-position: 0 68.76574307304786%;
}

.svg-pl-flag-sm-dims {
    width: 24px;
    height: 24px;
}

.svg-powered-by-stripe {
    @extend %svg-common;
    background-position: 0 70.27707808564232%;
}

.svg-powered-by-stripe-dims {
    width: 107px;
    height: 24px;
}

.svg-ro-flag {
    @extend %svg-common;
    background-position: 0 72.15189873417721%;
}

.svg-ro-flag-dims {
    width: 32px;
    height: 32px;
}

.svg-ro-flag-sm {
    @extend %svg-common;
    background-position: 0 73.80352644836272%;
}

.svg-ro-flag-sm-dims {
    width: 24px;
    height: 24px;
}

.svg-sk-flag {
    @extend %svg-common;
    background-position: 0 75.69620253164557%;
}

.svg-sk-flag-dims {
    width: 32px;
    height: 32px;
}

.svg-sk-flag-sm {
    @extend %svg-common;
    background-position: 0 77.32997481108312%;
}

.svg-sk-flag-sm-dims {
    width: 24px;
    height: 24px;
}

.svg-sl-flag {
    @extend %svg-common;
    background-position: 0 79.24050632911393%;
}

.svg-sl-flag-dims {
    width: 32px;
    height: 32px;
}

.svg-sl-flag-sm {
    @extend %svg-common;
    background-position: 0 80.85642317380352%;
}

.svg-sl-flag-sm-dims {
    width: 24px;
    height: 24px;
}

.svg-sr-flag {
    @extend %svg-common;
    background-position: 0 82.78481012658227%;
}

.svg-sr-flag-dims {
    width: 32px;
    height: 32px;
}

.svg-sr-flag-sm {
    @extend %svg-common;
    background-position: 0 84.38287153652394%;
}

.svg-sr-flag-sm-dims {
    width: 24px;
    height: 24px;
}

.svg-th-flag {
    @extend %svg-common;
    background-position: 0 86.32911392405063%;
}

.svg-th-flag-dims {
    width: 32px;
    height: 32px;
}

.svg-th-flag-sm {
    @extend %svg-common;
    background-position: 0 87.90931989924434%;
}

.svg-th-flag-sm-dims {
    width: 24px;
    height: 24px;
}

.svg-twitter-color-icn {
    @extend %svg-common;
    background-position: 0 90.33078880407125%;
}

.svg-twitter-color-icn-dims {
    width: 41px;
    height: 40px;
}

.svg-uk-flag {
    @extend %svg-common;
    background-position: 0 92.40506329113924%;
}

.svg-uk-flag-dims {
    width: 32px;
    height: 32px;
}

.svg-uk-flag-sm {
    @extend %svg-common;
    background-position: 0 93.95465994962217%;
}

.svg-uk-flag-sm-dims {
    width: 24px;
    height: 24px;
}

.svg-us-flag {
    @extend %svg-common;
    background-position: 0 95.9493670886076%;
}

.svg-us-flag-dims {
    width: 32px;
    height: 32px;
}

.svg-us-flag-sm {
    @extend %svg-common;
    background-position: 0 97.48110831234257%;
}

.svg-us-flag-sm-dims {
    width: 24px;
    height: 24px;
}

.svg-youtube-color-icn {
    @extend %svg-common;
    background-position: 0 100%;
}

.svg-youtube-color-icn-dims {
    width: 41px;
    height: 40px;
}

