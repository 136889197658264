/* Default */
$shadow-default: 0px 4px 10px rgba(78, 95, 124, 0.16);
$shadow-default-2: 0px 10px 20px 5px rgba(78, 95, 124, 0.1);
$shadow-default-red: 0px 4px 10px rgba(255, 97, 153, 0.2);
$shadow-default-green: 0px 4px 10px rgba(51, 199, 201, 0.2);
$shadow-default-blue: 0px 4px 10px rgba(106, 129, 246, 0.2);

/* Hover */
$shadow-hover: 0px 8px 16px rgba(78, 95, 124, 0.16);
$shadow-hover-red: 0px 8px 16px rgba(255, 97, 153, 0.2);
$shadow-hover-green: 0px 8px 16px rgba(51, 199, 201, 0.2);
$shadow-hover-image: 0px 8px 16px rgba(78, 95, 124, 0.32);

/* Pressed */
$shadow-pressed: 0px 1px 5px rgba(78, 95, 124, 0.16);
$shadow-pressed-red: 0px 1px 5px rgba(255, 97, 153, 0.2);
$shadow-pressed-green: 0px 1px 5px rgba(51, 199, 201, 0.2);

/* Product, FAQ, Card */
$shadow-product: 0px 0px 7px rgba(78, 95, 124, 0.07),
  0px 40px 80px -24px rgba(78, 95, 124, 0.3);
$shadow-product-dark: 0px 0px 7px rgba(78, 95, 124, 0.07),
  0px 40px 80px -24px rgba(78, 95, 124, 0.48);
$shadow-product-image: 0px 32px 64px -32px rgba(78, 95, 124, 0.48);
$shadow-image-card: 0px 40px 48px -24px rgba(78, 95, 124, 0.45);
$shadow-faq: 0px 24px 48px 5px rgba(78, 95, 124, 0.16);
$shadow-card: 0px 10px 20px 5px rgba(78, 95, 124, 0.1);
