$font-family-base: 'Open Sans', -apple-system, system-ui, BlinkMacSystemFont,
  'Segoe UI', Roboto, Ubuntu;

@import 'shared/colors.scss';

$pagination-hover: rgba(163, 163, 178, 0.1);

$grid-gutter-width: 48px;

// This will enable ms-n3 etc, it's off by default
$enable-negative-margins: true;

$modal-md: 560px;

$container-max-widths: (
  sm: 528px,
  md: 720px,
  lg: 944px,
  xl: 1200px,
);

$enable-caret: false;

$nav-tabs-link-active-color: $primary;
$nav-tabs-link-hover-border-color: transparent transparent $primary;
$nav-tabs-link-active-border-color: transparent transparent $primary;

$border-width-lg: 2px !default;

$enable-responsive-font-sizes: true;

$carousel-control-color: $primary;
$carousel-indicator-width: 8px;
$carousel-indicator-height: 8px;
$carousel-indicator-active-bg: $text-muted;
$carousel-control-width: 15%;
$carousel-control-prev-icon-bg-gray: url("data:image/svg+xml,<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M19.2921 11.567C19.6812 11.1842 19.6812 10.5569 19.2921 10.1741C18.9119 9.80004 18.3019 9.80004 17.9217 10.1741L12.7246 15.2871C12.3263 15.6789 12.3263 16.321 12.7246 16.7129L17.9217 21.8259C18.3019 22.2 18.9119 22.2 19.2921 21.8259C19.6812 21.4431 19.6812 20.8158 19.2921 20.433L15.5108 16.7129C15.1125 16.3211 15.1125 15.679 15.5108 15.2871L19.2921 11.567Z' fill='#C7C7D2'/></svg>");
$carousel-control-next-icon-bg-gray: url("data:image/svg+xml,<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.7743 11.567C13.3852 11.1842 13.3852 10.5569 13.7743 10.1741C14.1545 9.80004 14.7645 9.80004 15.1447 10.1741L20.3418 15.2871C20.7401 15.6789 20.7401 16.321 20.3418 16.7129L15.1447 21.8259C14.7645 22.2 14.1545 22.2 13.7743 21.8259C13.3852 21.4431 13.3852 20.8158 13.7743 20.433L17.5557 16.7129C17.9539 16.3211 17.9539 15.679 17.5557 15.2871L13.7743 11.567Z' fill='#C7C7D2'/></svg>");

$carousel-control-icon-width: 32px;

$pagination-border-width: 0;
$pagination-active-color: #6a81f6;
$pagination-active-bg: #eceffe;
$pagination-padding-y: 0.9rem;
$pagination-padding-x: 1.2rem;

$box-shadow: 0px 10px 20px 5px rgba(78, 95, 124, 0.1) !default;
$nav-shadow: 0px 4px 10px rgba(78, 95, 124, 0.16);

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

$dna-test-color: #00a9ab;
$custom-theme-colors: (
  'dna-test': $dna-test-color,
  'blood-test': $danger,
  'jumbotron-bg': $bg-lightblue,
);

$theme-colors: map-merge($theme-colors, $custom-theme-colors);

@import 'fonts.scss';
@import 'buttons.scss';
@import 'popovers.scss';
@import 'shadows';
@import '../assets/svgfont/prod/gpicons.scss';

@import 'bootstrap/scss/bootstrap';
@import 'shared/breakpoints';
@import 'tool.scss';

// Text muted is deprecated
.text-muted {
  color: $text-muted !important;
}

/*------------------------------------
  Pagination
------------------------------------*/
.page-item {
  font-weight: 600;

  .page-link {
    border-radius: 50rem !important;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $gray-5 !important;

    .gpicon-back,
    .gpicon-double-back,
    .gpicon-forward,
    .gpicon-double-forward {
      color: $gray-4;
    }

    @include media-breakpoint-down(md) {
      &[aria-label='First'],
      &[aria-label='Previous'],
      &[aria-label='Next'],
      &[aria-label='Last'] {
        width: 2.5rem;
        height: 2.5rem;
        margin-top: 0.25rem;
      }
    }
  }

  &.active,
  &:hover {
    .page-link {
      background: $pagination-hover;
    }
  }

  &.disabled {
    opacity: 0.7;

    .page-link {
      background: none !important;
    }
  }
}

/*------------------------------------
  Icon font default styling
------------------------------------*/
i[class*='gpicon-'] {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  line-height: 1 !important;
  height: 1.5rem;
  width: 1.5rem;
  font-size: 1.5rem;
  color: inherit;

  &:hover,
  &:focus,
  &:active {
    color: inherit;
  }

  &.gpicon-xs {
    height: 0.5rem;
    width: 0.5rem;
    font-size: 0.5rem;
  }

  &.gpicon-sm {
    height: 1rem;
    width: 1rem;
    font-size: 1rem;
  }

  &.gpicon-md {
    height: 1.25rem;
    width: 1.25rem;
    font-size: 1.25rem;
  }

  &.gpicon-lg {
    height: 2rem;
    width: 2rem;
    font-size: 2rem !important;
  }

  &.gpicon-xl {
    height: 3rem;
    width: 3rem;
    font-size: 3rem !important;

    @include media-breakpoint-up(md) {
      height: 4rem;
      width: 4rem;
      font-size: 4rem !important;
    }
  }
}

/*------------------------------------
  Shadows and borders (xs,sm,md,lg,xl) - TO BE UPDATED WHEN READY IN FIGMA
------------------------------------*/
$box-shadows: (
  0: none,
  1: $box-shadow-sm,
  2: $box-shadow,
  3: $box-shadow-lg,
) !default;

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop in (box-shadow) {
      @each $size, $value in $box-shadows {
        .box-shadow#{$infix}-#{$size} {
          #{$prop}: $value !important;
        }
      }
    }
    @each $prop in (border) {
      .#{$prop}#{$infix} {
        border: $border-width solid $border-color !important;
      }
    }
  }
}

/*------------------------------------
  Links
------------------------------------*/
a:hover {
  text-decoration: none;
}

.btn-link:focus,
.btn-link.focus {
  box-shadow: none;
}

/*------------------------------------
  Card
------------------------------------*/
:not(.accordion) > .card {
  border-radius: 12px;
  border: none;
  overflow: hidden;
}

/*------------------------------------
  Accordion
------------------------------------*/
.accordion {
  .card {
    border-radius: 0;
    border-right: none;
    border-left: none;

    [role='tab'] {
      padding: 0;

      button {
        padding: 0.75rem 0.5rem 0.75rem 1rem;

        &:focus,
        &:active {
          background: $bg-lightblue;
          box-shadow: none;
        }

        &:not(.collapsed) {
          .arrow-icon {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

/*------------------------------------
  List Group
------------------------------------*/
.list-group {
  list-style: none;

  .list-group-item {
    font-size: $font-size-sm;

    &:hover {
      background: transparent;
    }
    &:focus {
      background: $bg-lightblue;
      outline: none;
    }
  }

  /* Transparent */
  &.list-group-transparent .list-group-item {
    border-color: transparent;
    background-color: transparent;
  }

  /* Borderless */
  &.list-group-borderless .list-group-item {
    border: none;
  }
}

/*------------------------------------
  Backgrounds
------------------------------------*/
.bg-img-hero-fixed {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}

/*------------------------------------
  Carousel
------------------------------------*/
.carousel {
  outline: none !important;

  .carousel-indicators {
    li {
      border-radius: 100%;
      border-top-width: 12px;
      border-bottom-width: 12px;
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    margin-bottom: 1rem;
    top: unset !important;
    cursor: pointer;
    width: 32px;
  }

  .carousel-control-prev-icon {
    background-image: escape-svg($carousel-control-prev-icon-bg-gray);
  }

  .carousel-control-next-icon {
    background-image: escape-svg($carousel-control-next-icon-bg-gray);
  }

  .carousel-inner {
    overflow: visible;

    .row > div {
      margin-bottom: 24px;
    }
  }
}

@media (min-width: 768px) {
  .carousel {
    .carousel-inner {
      overflow: visible;

      .row > div {
        margin-bottom: 32px;
      }
    }
  }
}

/*------------------------------------
  Custom scroll
------------------------------------*/
$scrollbarColor: #e5e5ea;

.custom-scroll-parent {
  position: relative;
  overflow: hidden;
}

.custom-scroll {
  height: 100%;
  overflow-y: auto;
}

/* IE */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .custom-scroll {
    margin-right: -14px;
    padding-top: 32px;
    margin-top: -32px;
    margin-bottom: -32px;
    padding-bottom: 32px;

    scrollbar-base-color: #fff;
    scrollbar-face-color: $scrollbarColor;
    scrollbar-3dlight-color: $scrollbarColor;
    scrollbar-highlight-color: $scrollbarColor;
    scrollbar-track-color: #fff;
    scrollbar-arrow-color: $scrollbarColor;
    scrollbar-shadow-color: $scrollbarColor;
    scrollbar-dark-shadow-color: $scrollbarColor;
  }

  .custom-scroll:after {
    content: '';
    height: 32px;
    display: block;
  }
}

/* Edge */
@supports (-ms-ime-align: auto) {
  .custom-scroll-parent {
    padding-right: 10px;
  }
  .custom-scroll {
    margin-right: -22px;
    padding-top: 16px;
    margin-top: -16px;
    margin-bottom: -16px;
    padding-bottom: 16px;
  }

  .custom-scroll:after {
    content: '';
    height: 16px;
    display: block;
  }
}

/* Firefox 64 and up */
.custom-scroll {
  scrollbar-width: thin;
  scrollbar-color: $scrollbarColor transparent;
}

/* Chrome */
.custom-scroll::-webkit-scrollbar-track {
  background-color: #fafafa;
  width: 4px;
  height: 4px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: $scrollbarColor;
  background-clip: content-box;
  border-radius: 100px;
}

.custom-scroll::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/*------------------------------------
  Alerts
------------------------------------*/
.gp-alert {
  position: relative;
  padding: 0.25rem 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.75rem;
}

@include media-breakpoint-up(md) {
  .gp-alert {
    padding: 0.5rem 1.25rem;
  }
}

.gp-alert-danger {
  color: $text-highlights-important;
  background-color: $bg-highlights-important;
  border-color: $border-highlights-important;
}

.gp-alert-warning {
  color: $text-highlights-warning;
  background-color: $bg-highlights-warning;
  border-color: $border-highlights-warning;
}

.gp-alert-success {
  color: $text-highlights-confirmation;
  background-color: $bg-highlights-confirmation;
  border-color: $border-highlights-confirmation;
}

.gp-alert-info {
  color: $text-highlights-primary;
  background-color: $bg-highlights-primary;
  border-color: $border-highlights-primary;
}

/*------------------------------------
  Loaders
------------------------------------*/
.spinner-link {
  display: inline-flex;
  align-items: center;
  & > a,
  & > .spinner-border {
    margin-left: 0.25rem;
  }
}

/*------------------------------------
  Utilities
------------------------------------*/
/* Button utility */
@include media-breakpoint-down(md) {
  .gbtn-sm-icon-only {
    padding: 3px;
    border-radius: 8px !important;

    > i {
      margin-left: 0px;
      margin-right: 0px;
    }

    > span {
      display: none;
    }
  }
}

.remove-outline {
  outline: none !important;
}

.pointer {
  cursor: pointer;
}

.flex-even {
  flex: 1 !important;
}

.nav-shadow {
  box-shadow: $nav-shadow !important;
}

.text-gray {
  color: $gray-4 !important;
}

.bg-lightblue {
  background: $bg-lightblue !important;
}

.bg-lightblue-2 {
  background: $bg-lightblue-2 !important;
}

.text-fade {
  overflow: hidden;
  position: relative;
  white-space: nowrap;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 1rem;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  }
}

.text-danger {
  color: $danger !important;
}

/* IMPORTANT! The following text classes are deprecated, remove usage from project when possible. */
.text-teal {
  color: $teal !important;
}

.text-lightred {
  color: $light-red !important;
}

.text-label {
  color: $label-red !important;
}

.gp-tag {
  text-transform: uppercase;
  padding: 0.125rem 0.375rem;
  border-radius: 4px;
  width: fit-content !important;

  color: $gray-5;
  border: 1px solid $gray-3;

  &-red {
    color: $label-red;
    border: 1px solid $border-label-red;
  }

  &-green {
    color: $text-label-green;
    border: 1px solid $text-label-green;
  }
}

a {
  text-decoration: none;
}
